import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { BrandsComponent } from './brands/brands.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { PeopleComponent } from './people/people.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogModule } from '@angular/material/dialog';
import { AboutComponent } from './about/about.component';
import { LegalPopupComponent } from './legal-popup/legal-popup.component';
import { SnippetPopupComponent } from './snippet-popup/snippet-popup.component';
import { BrandsPopupComponent } from './brands-popup/brands-popup.component';
import { VideoSnippetPopupComponent } from './video-snippet-popup/video-snippet-popup.component';
import { PeopleCarouselComponent } from './people-carousel/people-carousel.component';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    BrandsComponent,
    SustainabilityComponent,
    PeopleComponent,
    ContactComponent,
    CallToActionComponent,
    AboutComponent,
    SnippetPopupComponent,
    LegalPopupComponent,
    BrandsPopupComponent,
    VideoSnippetPopupComponent,
    PeopleCarouselComponent,
    ProgressSpinnerDialogComponent,
    NotificationDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    GoogleMapsModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    HammerModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZ2lzYjJjIiwiYSI6ImNrYWhzeThkbjAzZ3oyeW9kaXV6aml6dXcifQ.yQxLlU9OgGy4AaSdbO-xeQ'
    }),
    MarkdownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
  // entryComponents: [PopupComponent]
})
export class AppModule { }
