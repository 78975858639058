import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private REST_API_ENDPOINT = environment.apiEndpoint;
  private EMAIL_ENDPOINT = environment.emailEndpoint;
  constructor(private httpClient: HttpClient) { }

  public getContact() {
    return this.httpClient.get(this.REST_API_ENDPOINT + '/contacts');
  }

  public sendEmail(payload) {
    return this.httpClient.post(this.EMAIL_ENDPOINT, payload);
  }
}
