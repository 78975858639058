<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">

    <div class="slide" [@animImageSlider]="currentSlide">
      <div *ngIf="i === currentSlide" class="slide-content">
        <img [src]="avatarUrls[i]">
        <div class="quote-container">
          <p class="standard-bold ">{{slide.Quote}}</p>
          <div class="quoter-container">
            <p class="small-standard-bold" style="margin-bottom: 0.5rem;">{{slide.Name}}</p>
            <p class="small-standard" style="font-style:italic;margin-top: 0.5rem;">{{slide.JobTitle}}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
<div class="carousel-mobile">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div class="slide" [@animImageSlider]="currentSlide" (swipeleft)="onNextClick()" (swiperight)="onPreviousClick()">
      <div *ngIf="i === currentSlide" class="slide-content-mobile">
        <p class="standard-bold" style="width: 70%;">
          {{slide.Quote}}</p>
        <div class="quoter-container-mobile">
          <img style="width: 8rem;" [src]="avatarUrls[i]">
          <div class="quoter-container">
            <p class="small-standard-bold" style="margin-bottom: 0.5rem;">{{slide.Name}}</p>
            <p class="small-standard" style="font-style:italic;margin-top: 0.5rem;">{{slide.JobTitle}}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
<!-- pagination -->
<div class="swiper-pagination">
  <span class="swiper-pagination-bullet" *ngFor="let slide of slides; let i = index"
    [ngStyle]="{'background': i === currentSlide ? '#5bc2dc' : '#000'}"></span>
</div>
