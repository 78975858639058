<div class="brands-popup-container">
  <div class="title-container">
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="popup-content-container">
    <img [src]="brandsPopUpImageUrl">
    <div markdown class="popup-text">
      {{data.eachBrandPopupInfo.PopUpDescription}}
    </div>
  </div>
</div>
