<div *ngIf="peopleComponent" (window:resize)="refreshAOS()">
  <div class="container">
    <div class="header-container">
      <div class="subheader-container">
        <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" class="mega">
          {{peopleComponent.Title}}</h1>
        <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" class="standard">
          {{peopleComponent.Description}}</p>
      </div>
      <img data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" [src]="bannerImageUrl"
        (load)="refreshAOS()">
    </div>
    <div class="section-container">
      <div class="engage-container">
        <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="600" class="huge title-centered">
          {{peopleComponent.SectionTitle1}}</h1>
        <div class="snippet-container">
          <div class="each-snippet-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="700"
            *ngFor="let eachSnippet of peopleComponent.SnippetItem; let j=index">
            <img [src]="snippetImageUrls[j]" (load)="refreshAOS()">
            <p class="small-standard-bold">{{eachSnippet.SnippetTitle}}</p>
            <p class="small-standard" style="width:quarter-width">{{eachSnippet.SnippetDescription}}</p>
            <mat-chip-list>
              <mat-chip *ngIf="eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                (click)="openDialog(eachSnippet)">Watch
                <mat-icon>play_circle_outline</mat-icon>
              </mat-chip>
              <mat-chip *ngIf="!eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                (click)="openDialog(eachSnippet)">Learn More
                <mat-icon>keyboard_arrow_right</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <button mat-icon-button color="#414042" *ngIf="eachSnippet.VideoBoolean" class="cursor mobile-button"
              (click)="openDialog(eachSnippet)">
              <mat-icon class="icon">play_circle_outline</mat-icon>
            </button>
            <button mat-icon-button color="#414042" *ngIf="!eachSnippet.VideoBoolean" class="cursor mobile-button"
              (click)="openDialog(eachSnippet)">
              <mat-icon class="icon">open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-section" >
      <h1 class="huge" data-aos="fade-up" data-aos-duration="800" data-aos-delay="250">{{peopleComponent.SectionTitle2}}
      </h1>
      <div class="carousel-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="250">
        <app-people-carousel [slides]="peopleComponent.Carousels" [avatarUrls]="avatarUrls"></app-people-carousel>
      </div>
    </div>
  </div>
  <app-call-to-action [title]="peopleComponent.CallToAction.Title"
    [description]="peopleComponent.CallToAction.Description" [actionTitle]="peopleComponent.CallToAction.ActionTitle"
    [actionURL]="peopleComponent.CallToAction.ActionURL">
  </app-call-to-action>
  <app-footer></app-footer>
</div>
