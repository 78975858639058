import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA} from "@angular/material/dialog";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-brands-popup',
  templateUrl: './brands-popup.component.html',
  styleUrls: ['./brands-popup.component.scss']
})
export class BrandsPopupComponent implements OnInit {
  eachBrandPopupInfo = null;
  brandsPopUpImageUrl = [];
  private REST_API_ENDPOINT = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

      this.brandsPopUpImageUrl.push((this.REST_API_ENDPOINT + this.data.eachBrandPopupInfo.PopUpImage.url))
  }
}
