import { Component, OnInit, QueryList } from '@angular/core';
import { IdentityService } from '../services/identity.service';
import { environment } from '../../environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  identityComponent = null;
  mapImageUrl = '';
  sectionImageUrl = '';
  profileImageUrl = [];
  private REST_API_ENDPOINT = '';
  private ASSETS_ENDPOINT = environment.assetsEndpoint;
  center = { lat: 32, lng: 84 };
  zoom = [2.2];
  imageLoaded = false;
  locationTagUrl = '';
  lngLatArray = [];
  constructor(
    private identityService: IdentityService
  ) { }

  ngOnInit() {
    this.identityService.getIdentity().subscribe(data => {
      this.identityComponent = data[0];
      this.sectionImageUrl = this.ASSETS_ENDPOINT + this.identityComponent.SectionImage.url.replace(/^(.*?)\.com/, '');
      this.locationTagUrl = this.ASSETS_ENDPOINT + this.identityComponent.MapLocationTag.url.replace(/^(.*?)\.com/, '');
      for (const eachPerson of this.identityComponent.ManagementTeam) {
        this.profileImageUrl.push(this.ASSETS_ENDPOINT + eachPerson.Profile.url.replace(/^(.*?)\.com/, ''));
      }
      for (const eachCountry of this.identityComponent.CountryTags) {
        this.lngLatArray.push({ lon: eachCountry.googleMapLongitude, lat: eachCountry.googleMapLatitude });
        // this.lngLatArray.push([eachCountry.googleMapLongitude, eachCountry.googleMapLatitude]);
      }
      AOS.refresh();
    });
  }

  refreshAOS() {
    AOS.refresh();
    // if phone screen, change zoom of map
    if (window.innerWidth <= 500) {
      this.center = { lat: 28, lng: 60 };
      this.zoom = [1];
    } else {
      this.center = { lat: 32, lng: 84 };
      this.zoom = [2.2];
    }
  }

}
