import { Component, OnInit } from '@angular/core';
import { PeopleService } from '../services/people.service';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnippetPopupComponent } from '../snippet-popup/snippet-popup.component';
import * as AOS from 'aos';
import { VideoSnippetPopupComponent } from '../video-snippet-popup/video-snippet-popup.component';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  peopleComponent = null;
  bannerImageUrl = '';
  snippetImageUrls = [];
  avatarUrls = [];
  private REST_API_ENDPOINT = '';
  private ASSETS_ENDPOINT = environment.assetsEndpoint;
  constructor(private peopleService: PeopleService, private dialog: MatDialog) { }

  ngOnInit() {
    this.peopleService.getPeople().subscribe(data => {
      this.peopleComponent = data[0];
      this.bannerImageUrl = this.ASSETS_ENDPOINT + this.peopleComponent.BannerImage.url.replace(/^(.*?)\.com/, '');
      for (const eachSnippet of this.peopleComponent.SnippetItem) {
        this.snippetImageUrls.push(this.ASSETS_ENDPOINT + eachSnippet.SnippetMedia.url.replace(/^(.*?)\.com/, ''));
      }
      for (const eachSlide of this.peopleComponent.Carousels) {
        this.avatarUrls.push(this.ASSETS_ENDPOINT + eachSlide.Avatar.url.replace(/^(.*?)\.com/, ''));
      }
    });
  }

  openDialog(eachSnippetPopupInfo) {
    if (eachSnippetPopupInfo.VideoBoolean) {
      this.dialog.open(VideoSnippetPopupComponent, {
        maxWidth: undefined,
        maxHeight: undefined,
        panelClass: 'video-dialog-container',
        data: { eachSnippetPopupInfo: eachSnippetPopupInfo }
      });
    } else {
      this.dialog.open(SnippetPopupComponent, {
        maxWidth: undefined,
        maxHeight: undefined,
        panelClass: 'general-dialog',
        data: { eachSnippetPopupInfo: eachSnippetPopupInfo }
      });
    }
  }

  refreshAOS() {
    AOS.refresh();
  }
}
