<div *ngIf="brandsComponent" (window:resize)="refreshAOS()">
  <div class='container'>
    <div class="header-container">
      <div class="subheader-container">
        <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" class="mega">{{brandsComponent.Title}}</h1>
        <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" class="standard">
          {{brandsComponent.Description}}</h1>
      </div>
      <img data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" [src]="bannerImageUrl"
        (load)="refreshAOS()">
    </div>
    <div class="overall-container">
      <div class="section-container">
        <div class="section-content-container beverage-background">
          <div class="text-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
            <h1 class="huge">{{brandsComponent.SectionTitle1}}</h1>
            <p class="standard">{{brandsComponent.SectionDescription1}}</p>
          </div>
          <div class="brands-container">
            <div *ngFor="let eachBrand of brandsComponent.Brand; let i = index" class="each-brand" data-aos="fade-up"
              data-aos-duration="800" data-aos-delay="700">
              <img [src]="brandsImageUrl1[i]" (load)="refreshAOS()">
              <mat-chip-list>
                <mat-chip selected class="suntory-chip-button desktop-button" (click)="openDialog(eachBrand)">
                  {{eachBrand.ActionTitle}}
                  <mat-icon matChipTrailingIcon style="margin: 0;">keyboard_arrow_right</mat-icon>
                </mat-chip>
              </mat-chip-list>
              <button mat-icon-button class="mobile-button" (click)="openDialog(eachBrand)" aria-label="new tab">
                <mat-icon class="icon">open_in_new</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="section-content-container health-enrichment-background">
          <div class="text-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
            <h1 class="huge">{{brandsComponent.SectionTitle2}}</h1>
            <h1 class="standard">{{brandsComponent.SectionDescription2}}</h1>
          </div>
          <div class="brands-container">
            <div *ngFor="let eachBrand of brandsComponent.Brand2; let j = index" class="each-brand" data-aos="fade-up"
              data-aos-duration="800" data-aos-delay="300">
              <img [src]="brandsImageUrl2[j]" (load)="refreshAOS()">
              <mat-chip-list>
                <mat-chip selected class="suntory-chip-button desktop-button" (click)="openDialog(eachBrand)">
                  {{eachBrand.ActionTitle}}
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </mat-chip>
              </mat-chip-list>
              <button mat-icon-button class="mobile-button" (click)="openDialog(eachBrand)" aria-label="new tab">
                <mat-icon class="icon">open_in_new</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-call-to-action [title]="brandsComponent.CallToAction.Title"
      [description]="brandsComponent.CallToAction.Description" [actionTitle]="brandsComponent.CallToAction.ActionTitle"
      [actionURL]="brandsComponent.CallToAction.ActionURL">
    </app-call-to-action>
    <app-footer></app-footer>
  </div>
</div>
