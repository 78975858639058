import { HomeService } from './../services/home.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import * as AOS from 'aos';
import { BrandsPopupComponent } from '../brands-popup/brands-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  homeComponent = null;
  bannerImageUrl = '';
  bannerMobileImageUrl = '';
  brandsImageUrls = [];
  section2ImageUrl = '';
  private REST_API_ENDPOINT = '';
  private ASSETS_ENDPOINT = environment.assetsEndpoint;
  constructor(private homeService: HomeService, private dialog: MatDialog) { }

  ngOnInit() {
    this.homeService.getHome().subscribe(data => {
      this.homeComponent = data[0];
      this.bannerImageUrl = this.ASSETS_ENDPOINT + this.homeComponent.HomeBannerImage.url.replace(/^(.*?)\.com/, '');
      this.bannerMobileImageUrl = this.ASSETS_ENDPOINT + this.homeComponent.HomeBannerImageMobile.url.replace(/^(.*?)\.com/, '');
      for (const eachBrand of this.homeComponent.Brand) {
        this.brandsImageUrls.push(this.ASSETS_ENDPOINT + eachBrand.Logo.url.replace(/^(.*?)\.com/, ''));
      }
      this.section2ImageUrl = this.ASSETS_ENDPOINT + this.homeComponent.SectionImage2.url.replace(/^(.*?)\.com/, '');
    });
  }

  refreshAOS() {
    AOS.refresh();
  }

  openDialog(eachBrandPopupInfo) {
    this.dialog.open(BrandsPopupComponent, {
      panelClass: 'general-dialog',
      maxWidth: undefined,
      maxHeight: undefined,
      data: { eachBrandPopupInfo: eachBrandPopupInfo }
    });
  }
}
