<div class="popup-container">
  <div class="title-container">
    <h1 style="margin-top:0;margin-bottom:0;">{{data.eachPopupInfo.Label}}</h1>
    <button mat-icon-button class="close-icon" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div markdown mat-dialog-content class="popup-content">
    {{data.eachPopupInfo.PopUpText}}</div>
</div>
