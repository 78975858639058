import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-snippet-popup',
  templateUrl: './snippet-popup.component.html',
  styleUrls: ['./snippet-popup.component.scss']
})

export class SnippetPopupComponent implements OnInit {
  eachSnippetPopupInfo = null;
  popUpImageUrl = [];
  private REST_API_ENDPOINT = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) { }

  videoUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.eachSnippetPopupInfo.PopUpVideoLink);
  }

  ngOnInit(): void {
    console.log(this.data);
    if (!this.data.eachSnippetPopupInfo.VideoBoolean && this.data.eachSnippetPopupInfo.PopUpImageOrVideo) {
      this.popUpImageUrl.push(this.REST_API_ENDPOINT + this.data.eachSnippetPopupInfo.PopUpImageOrVideo.url);
    }
  }

}
