<div *ngIf="sustainabilityComponent" (window:resize)="refreshAOS()">
  <div class="container">
    <div class="header-container">
      <div class="header-content-container">
        <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" class="mega quarter-width">
          {{sustainabilityComponent.Title}}</h1>
        <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" class="standard quarter-width">
          {{sustainabilityComponent.Description}}</p>
      </div>
    </div>
    <div class="section-container environment-background">
      <h1 class="huge"  data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">{{sustainabilityComponent.EnvironmentSection.Title}}</h1>
      <p class="standard quarter-width"  data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">{{sustainabilityComponent.EnvironmentSection.Description}}</p>
      <div class="environment-cluster-container">
        <div *ngFor="let eachCluster of sustainabilityComponent.EnvironmentCluster; let i=index"
          class="each-cluster-container">
          <div class="each-cluster-content">
            <h1 class="big" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">{{eachCluster.ClusterTitle}}
            </h1>
            <div class="environment-snippet-container">
              <div *ngFor="let eachSnippet of eachCluster.SnippetItem; let j=index"
                class="each-environment-snippet-container" data-aos="fade-up" data-aos-duration="800"
                data-aos-delay="300">
                <img [src]="snippetImageUrls[i][j]" (load)="refreshAOS()">
                <p class="small-standard-bold">{{eachSnippet.SnippetTitle}}</p>
                <p class="small-standard" style="width:quarter-width">{{eachSnippet.SnippetDescription}}</p>
                <mat-chip-list>
                  <mat-chip *ngIf="eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                    (click)="openDialog(eachSnippet)">Watch
                    <mat-icon>play_circle_outline</mat-icon>
                  </mat-chip>
                  <mat-chip *ngIf="!eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                    (click)="openDialog(eachSnippet)">Learn More
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <button mat-icon-button color="#414042" *ngIf="eachSnippet.VideoBoolean" class="cursor mobile-button"
                  (click)="openDialog(eachSnippet)">
                  <mat-icon class="icon">play_circle_outline</mat-icon>
                </button>
                <button mat-icon-button color="#414042" *ngIf="!eachSnippet.VideoBoolean" class="cursor mobile-button"
                  (click)="openDialog(eachSnippet)">
                  <mat-icon class="icon">open_in_new</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-container social-background" >
      <h1 class="huge" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">{{sustainabilityComponent.SocialSection.Title}}</h1>
      <p class="standard quarter-width" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">{{sustainabilityComponent.SocialSection.Description}}</p>
      <div class="social-snippet-container">
        <div class="social-snippet-content" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
          <div *ngFor="let eachSnippet of sustainabilityComponent.SocialSnippets; let j=index"
            class="each-social-snippet-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
            <img [src]="socialSnippetImageUrls[j]" (load)="refreshAOS()">
            <div class="each-social-snippet-text">
              <p class="small-standard-bold" style="width:100%">{{eachSnippet.SnippetTitle}}</p>
              <p class="small-standard">{{eachSnippet.SnippetDescription}}</p>
            </div>
            <mat-chip-list>
              <mat-chip *ngIf="eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                (click)="openDialog(eachSnippet)">Watch
                <mat-icon>play_circle_outline</mat-icon>
              </mat-chip>
              <mat-chip *ngIf="!eachSnippet.VideoBoolean" selected class="suntory-chip-button desktop-button"
                (click)="openDialog(eachSnippet)">Learn More
                <mat-icon>keyboard_arrow_right</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <button mat-icon-button color="#414042" *ngIf="eachSnippet.VideoBoolean" class="cursor mobile-button"
              (click)="openDialog(eachSnippet)">
              <mat-icon class="icon">play_circle_outline</mat-icon>
            </button>
            <button mat-icon-button color="#414042" *ngIf="!eachSnippet.VideoBoolean" class="cursor mobile-button"
              (click)="openDialog(eachSnippet)">
              <mat-icon class="icon">open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-call-to-action [title]="sustainabilityComponent.CallToAction.Title"
    [description]="sustainabilityComponent.CallToAction.Description"
    [actionTitle]="sustainabilityComponent.CallToAction.ActionTitle"
    [actionURL]="sustainabilityComponent.CallToAction.ActionURL">
  </app-call-to-action>
  <app-footer></app-footer>
</div>
