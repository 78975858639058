import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  private REST_API_ENDPOINT = environment.apiEndpoint;

  constructor(private httpClient: HttpClient) { }

  public getBrands() {
    return this.httpClient.get(this.REST_API_ENDPOINT + '/brands');
  }
}
