<!-- THIS IS FOR VIEW > PHONE -->
<div *ngIf="navbarComponent" class="navbar-container" data-aos="fade-down" data-aos-delay="500" data-aos-duration="800"
  data-aos-once="false">
  <img src="../../assets/img/suntory.svg" routerLink="/" class="home-logo" />
  <!-- <div class="vertical"></div> -->
  <div class="navbar-titles">
    <span *ngFor="let eachNavbarTitle of navbarComponent.NavbarItem" style="display: inline-block;">
      <a [routerLink]="eachNavbarTitle.URL" [ngClass]="{'underline-bold': eachNavbarTitle.URL === currentPath }">
        {{eachNavbarTitle.Title}}
      </a>
    </span>
  </div>
</div>
<!-- THIS IS FOR VIEW <= PHONE -->
<div class="navbar-container-phone">
  <img src="../../assets/img/suntory.svg" routerLink="/" class="home-logo" alt="logo" id="logo">
  <button mat-icon-button (click)="toggle()" *ngIf="!isToggled">
    <mat-icon>menu</mat-icon>
  </button>
  <button mat-icon-button (click)="toggle()" *ngIf="isToggled">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="navbarComponent" class="navbar-container-phone-expanded" [class.collapse]="isToggled"
  [class.uncollapse]="!isToggled && !isPristine">
  <div class="navbar-content">
    <p routerLink="/home" (click)="toggle()">Home</p>
    <div *ngFor="let eachNavbarTitle of navbarComponent.NavbarItem">
      <p [routerLink]="eachNavbarTitle.URL" (click)="toggle()">
        {{eachNavbarTitle.Title}}
      </p>
    </div>
  </div>
</div>
