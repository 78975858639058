import { VideoSnippetPopupComponent } from './../video-snippet-popup/video-snippet-popup.component';
import { Component, OnInit } from '@angular/core';
import { SustainabilityService } from '../services/sustainability.service';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnippetPopupComponent } from '../snippet-popup/snippet-popup.component';
import * as AOS from 'aos';

@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.scss']
})
export class SustainabilityComponent implements OnInit {

  sustainabilityComponent = null;
  snippetImageUrls = [];

  socialSnippetImageUrls = [];

  private REST_API_ENDPOINT = '';
  private ASSETS_ENDPOINT = environment.assetsEndpoint;
  constructor(private sustainabilityService: SustainabilityService, private dialog: MatDialog) { }

  ngOnInit() {
    this.sustainabilityService.getSustainability().subscribe(data => {
      this.sustainabilityComponent = data[0];

      for (const eachCluster of this.sustainabilityComponent.EnvironmentCluster) {
        const eachClusterUrl = [];
        for (const eachSnippet of eachCluster.SnippetItem) {
          if (eachSnippet && eachSnippet.SnippetMedia.url) {
            eachClusterUrl.push(this.ASSETS_ENDPOINT + eachSnippet.SnippetMedia.url.replace(/^(.*?)\.com/, ''));

          } else {
            eachClusterUrl.push('');
          }
        }
        this.snippetImageUrls.push(eachClusterUrl);
      }
      for (const eachSocialSnippet of this.sustainabilityComponent.SocialSnippets) {
        this.socialSnippetImageUrls.push(this.ASSETS_ENDPOINT + eachSocialSnippet.SnippetMedia.url.replace(/^(.*?)\.com/, ''));
      }
      AOS.refresh();
    });
  }

  openDialog(eachSnippetPopupInfo) {
    if (eachSnippetPopupInfo.VideoBoolean) {
      this.dialog.open(VideoSnippetPopupComponent, {
        maxWidth: undefined,
        maxHeight: undefined,
        panelClass: 'video-dialog-container',
        data: { eachSnippetPopupInfo: eachSnippetPopupInfo }
      });
    } else {
      this.dialog.open(SnippetPopupComponent, {
        panelClass: 'general-dialog',
        maxWidth: undefined,
        maxHeight: undefined,
        data: { eachSnippetPopupInfo: eachSnippetPopupInfo }
      });
    }

  }

  refreshAOS() {
    AOS.refresh();
  }
}
