<div *ngIf="contactComponent" class="container" (window:resize)="refreshAOS()">
  <div class="column">
    <h1 class="mega" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">{{contactComponent.Title}}</h1>
    <form [formGroup]='contactFormGroup' class="form-container" (ngSubmit)="sendEmail()" data-aos="fade-up"
      data-aos-duration="800" data-aos-delay="400">
      <div class="each-row">
        <mat-form-field floatLabel="always" class="half-width">
          <mat-label>Name</mat-label>
          <input matInput placeholder="John" formControlName="name">
          <mat-error *ngIf="contactFormGroup.get('name').hasError('required')">Required
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="half-width">
          <mat-label>Location</mat-label>
          <mat-select formControlName="country" placeholder="Singapore">
            <mat-option *ngFor="let eachCountry of Countries" [value]="eachCountry">
              {{eachCountry}}</mat-option>
          </mat-select>
          <mat-error *ngIf="contactFormGroup.get('country').hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="each-row">
        <mat-form-field floatLabel="always" class="half-width">
          <mat-label>Email Address</mat-label>
          <input type="email" matInput placeholder="johndoe@suntory.com" formControlName="emailAddress">
          <mat-error *ngIf="contactFormGroup.status === 'INVALID'">Required
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="half-width">
          <mat-label>Topic</mat-label>
          <!-- <input matInput placeholder="General Enquiries" formControlName="purpose"> -->
          <mat-select formControlName="purpose" placeholder="General Enquiries">
            <mat-option *ngFor="let eachTopic of contactComponent.Topics" [value]="eachTopic">
              {{eachTopic.Title}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactFormGroup.get('purpose').hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field floatLabel="always">
        <mat-label>Is this related to a specific brand? - Optional</mat-label>
        <mat-select multiple formControlName="brandName" placeholder="Ribena, Lucozade, etc">
          <mat-option *ngFor="let eachBrand of contactComponent.Brands" [value]="eachBrand">
            {{eachBrand.Title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <mat-label>Message</mat-label>
        <textarea matInput placeholder="Enter a message" formControlName="message"></textarea>
        <mat-error *ngIf="contactFormGroup.get('message').hasError('required')">Required
        </mat-error>
      </mat-form-field>
      <div class="submit-button-container">
        <button mat-flat-button class="large-button" color="primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
  <div class="column offices-column" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
    <h1 class="huge">Our Offices</h1>
    <mat-accordion>
      <mat-expansion-panel style="background: none;box-shadow: none;"
        *ngFor="let eachCountry of contactComponent.Country; let i = index;"
        (opened)="changeBackground(eachCountry, i)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="standard-bold">
              {{eachCountry.CountryName}}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-container">
          <div style="font-size: 1rem;" class="standard" *ngFor="let eachLocation of eachCountry.Location">
            <div *ngFor="let eachEntity of eachLocation.EntityName">
              <p style="font-weight: bold;">{{eachEntity.Title}}</p>
            </div>
            <p class="standard" style="white-space: pre-line; font-size: 1rem;">
              {{eachLocation.Address}}</p>
            <p>{{eachLocation.PhoneNumber}}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>
<div class="map-container" *ngIf="contactComponent">
  <mgl-map [style]="'mapbox://styles/gisb2c/ckakod9po31o81iqtkep2y3r8'" [zoom]="zoom" [center]="center" movingMethod="flyTo">
  </mgl-map>
</div>
<div *ngIf="contactComponent" style="flex-direction: column;padding: 0 16px 0 16px;" class="column offices-column-mobile" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
  <h1 class="huge">Our Offices</h1>
  <mat-accordion>
    <mat-expansion-panel style="background: none;box-shadow: none;"
      *ngFor="let eachCountry of contactComponent.Country; let i = index;"
      (opened)="changeBackground(eachCountry, i)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="standard-bold">
            {{eachCountry.CountryName}}</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-container">
        <div style="font-size: 1rem;" class="standard" *ngFor="let eachLocation of eachCountry.Location">
          <div *ngFor="let eachEntity of eachLocation.EntityName">
            <p style="font-weight: bold;">{{eachEntity.Title}}</p>
          </div>
          <p class="standard" style="white-space: pre-line; font-size: 1rem;">
            {{eachLocation.Address}}</p>
          <p>{{eachLocation.PhoneNumber}}</p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
<app-footer></app-footer>
