import { Component, OnInit, Input } from "@angular/core";
import {
  trigger,
  transition,
  query,
  style,
  animate,
  group,
} from "@angular/animations";
import * as AOS from "aos";
import { Subscription, interval } from "rxjs";
import * as Hammer from "hammerjs";
const left = [
  group([
    // tslint:disable-next-line:max-line-length
    query(
      ":enter",
      [
        style({ transform: "translateX(-100%)" }),
        animate(
          "500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)",
          style({ transform: "translateX(0%)" })
        ),
      ],
      {
        optional: true,
      }
    ),
    // tslint:disable-next-line:max-line-length
    query(
      ":leave",
      [
        style({ transform: "translateX(0%)" }),
        animate(
          "500ms cubic-bezier(0.785, 0.135, 0.15, 0.86)",
          style({ transform: "translateX(100%)" })
        ),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(100%)" }),
        animate("500ms ease-out", style({ transform: "translateX(0%)" })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ":leave",
      [
        style({ transform: "translateX(0%)" }),
        animate("500ms ease-out", style({ transform: "translateX(-100%)" })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];
@Component({
  selector: "app-people-carousel",
  templateUrl: "./people-carousel.component.html",
  styleUrls: ["./people-carousel.component.scss"],
  animations: [
    trigger("animImageSlider", [
      transition(":increment", right),
      transition(":decrement", left),
    ]),
  ],
})
export class PeopleCarouselComponent implements OnInit {
  @Input() slides;
  @Input() avatarUrls;
  currentSlide = 0;
  subscription: Subscription;
  isClicked = false;
  constructor() {}

  ngOnInit(): void {
    const source = interval(15000);
    this.subscription = source.subscribe((val) => {
      if (this.isClicked) {
        this.subscription.unsubscribe();
      } else {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.length ? 0 : next;
      }
    });
  }

  onPreviousClick() {
    this.isClicked = true;
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    this.isClicked = true;
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  refreshAOS() {
    AOS.refresh();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
