<div *ngIf="identityComponent" (window:resize)="refreshAOS()">
  <div class='container'>
    <div style="min-height: 90vh;">
      <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" class="mega">{{identityComponent.Title}}</h1>
      <h1 data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" class="standard quarter-width">
        {{identityComponent.Description}}</h1>
      <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="400" class="identity-type-container">
        <div *ngFor="let eachtype of identityComponent.IdentityType">
          <h1 class="identity-count">{{eachtype.IdentityCount}}</h1>
          <div style="display: flex;height: 2rem;align-items: center;">
            <h1 class="standard-bold" style="margin-top:0;margin-bottom: 0;"> {{eachtype.IdentityTitle}}</h1>
            <a mat-icon-button [href]="eachtype.URL" *ngIf="eachtype.URL && eachtype.URL !== ''">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="map-container">
      <mgl-map [style]="'mapbox://styles/gisb2c/ckakod9po31o81iqtkep2y3r8'" [zoom]="zoom" [center]="center">
        <mgl-marker *ngFor="let eachCountry of lngLatArray" [lngLat]="eachCountry">
          <div class="marker">
            <img style="width: 100%;" [src]="locationTagUrl"></div>
        </mgl-marker>
      </mgl-map>
    </div>
    <div class="about-container">
      <div class="promise-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
        <div class="promise-content">
          <div class="promise-image-container">
            <img [src]="sectionImageUrl" (load)="refreshAOS()">
          </div>
          <div class="promise-text-container">
            <h1 class="huge">{{identityComponent.SectionTitle1}}</h1>
            <p class="standard">{{identityComponent.SectionDescription1}}</p>
          </div>
        </div>
      </div>
      <div class="mission-vision-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
        <div class="mission-vision-content">
          <div class="each-column">
            <!-- <img style="width: 8rem;" src="../../assets/img/mission-icon.svg"> -->
            <h1 class="huge">{{identityComponent.SectionTitle2}}</h1>
            <p class="standard">{{identityComponent.SectionDescription2}}</p>
          </div>
          <div class="each-column">
            <!-- <img style="width: 8rem;" src="../../assets/img/vision-icon.svg"> -->
            <h1 class="huge">{{identityComponent.SectionTitle3}}</h1>
            <p class="standard">{{identityComponent.SectionDescription3}}</p>
          </div>
        </div>
      </div>
      <div class="mission-vision-container">
        <div class="mission-vision-mobile-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
          <div class="each-mobile-content">
            <!-- <img style="width: 8rem;" src="../../assets/img/mission-icon.svg"> -->
            <h1 class="huge">{{identityComponent.SectionTitle2}}</h1>
            <p class="standard">{{identityComponent.SectionDescription2}}</p>
          </div>
        </div>
        <div class="mission-vision-mobile-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
          <div class="each-mobile-content">
            <!-- <img style="width: 8rem;" src="../../assets/img/vision-icon.svg"> -->
            <h1 class="huge">{{identityComponent.SectionTitle3}}</h1>
            <p class="standard">{{identityComponent.SectionDescription3}}</p>
          </div>
        </div>
      </div>
      <div class="values-container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
        <div class="values-content">
          <!-- <img style="width: 8rem;" src="../../assets/img/values-icon.svg"> -->
          <h1 class="huge">{{identityComponent.SectionTitle4}}</h1>
          <p class="standard" style="width: 60%;">{{identityComponent.SectionDescription4}}</p>
          <div class="columns-container">
            <div class="each-column" *ngFor="let eachSubSection of identityComponent.SubSection">
              <h1 class="huge">{{eachSubSection.SubSectionNumber}} </h1>
              <div class="column-text-container">
                <h1 class="standard-bold">{{eachSubSection.SubSectionTitle}}</h1>
                <p class="standard">{{eachSubSection.SubSectionDescription}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="management-container">
      <div class="management-content">
        <h1 class="huge" style="text-align: center;" data-aos="fade-up" data-aos-duration="800" data-aos-delay="800">
          {{identityComponent.SectionTitle5}}</h1>
        <div class="people-container">
          <div *ngFor="let eachPerson of identityComponent.ManagementTeam; let i = index; first as isFirst;"
            class="each-person" [ngClass]="{'each-person-first': isFirst}" data-aos="fade-up" data-aos-duration="800"
            data-aos-delay="800">
            <img [src]=profileImageUrl[i] (load)="refreshAOS()">
            <p class="small-standard-bold each-person-name">{{eachPerson.Name}}</p>
            <p class="small-standard each-person-title">{{eachPerson.Title}}</p>
          </div>
        </div>
      </div>
    </div>
    <app-call-to-action [title]="identityComponent.CallToAction.Title"
      [description]="identityComponent.CallToAction.Description"
      [actionTitle]="identityComponent.CallToAction.ActionTitle" [actionURL]="identityComponent.CallToAction.ActionURL">
    </app-call-to-action>
    <app-footer></app-footer>
  </div>
