import { NotificationDialogComponent } from './../notification-dialog/notification-dialog.component';
import { Component, OnInit, ViewChildren, QueryList, Input, OnDestroy } from '@angular/core';
import { ContactService } from '../services/contact.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { countries } from '../../assets/json/countries';
import * as AOS from 'aos';
import { Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  @ViewChildren('sbfaMap') sbfaMap: QueryList<any>;
  contactFormGroup: FormGroup;
  brandName = new FormControl();
  contactComponent = null;
  Countries: string[] = countries;
  googleMapCountries = [];
  center = null;
  zoom = [1];
  display?: google.maps.LatLngLiteral;
  subscription: Subscription;
  googleMapCounter = 0;
  constructor(
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.contactFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      purpose: [null, [Validators.required]],
      message: ['', Validators.required],
      brandName: [null],
    });
    this.contactService.getContact().subscribe(data => {
      this.contactComponent = data[0];
      this.googleMapCountries = this.contactComponent.Country;
      const firstCountry = this.googleMapCountries[0];
      this.center = { lat: firstCountry.googleMapLatitude, lng: firstCountry.googleMapLongitude };
      this.zoom = [firstCountry.googleMapZoom];
      this.refreshAOS();
    });
    // EVERY 2 SECOND CHANGE THE GOOGLE MAP
    // this.googleMapCounter = 0;
    // const source = interval(10000);
    // this.subscription = source.subscribe(val => {
    //   if (this.googleMapCounter === this.googleMapCountries.length - 1) {
    //     this.googleMapCounter = 0;
    //   } else {
    //     this.googleMapCounter += 1;
    //   }
    //   console.log(this.googleMapCounter);
    //   console.log(this.googleMapCountries[this.googleMapCounter]);
    //   const currentGoogleMapLocation = this.googleMapCountries[this.googleMapCounter];
    //   this.center = { lat: currentGoogleMapLocation.googleMapLatitude, lng: currentGoogleMapLocation.googleMapLongitude };
    //   this.zoom = currentGoogleMapLocation.googleMapZoom;
    // });
  }

  formatBrandsArray(brandsArray) {
    if (brandsArray) {
      let result = '';
      for (let i = 0; i < brandsArray.length; i++) {
        if (i === brandsArray.length - 1) {
          result += brandsArray[i].Title;
        } else {
          result += brandsArray[i].Title + ', ';
        }
      }
      return result;
    } else {
      return '';
    }
  }

  sendEmail() {
    if (this.contactFormGroup.valid) {
      const contactFormValues = this.contactFormGroup.value;
      const brandsString = this.formatBrandsArray(contactFormValues.brandName);
      const emailPayload = {
        enquirerAddress: contactFormValues.emailAddress,
        name: contactFormValues.name,
        country: contactFormValues.country,
        brands: brandsString,
        purpose: contactFormValues.purpose.Title,
        message: contactFormValues.message
      };
      const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
        maxWidth: undefined,
        maxHeight: undefined,
      });
      this.contactService.sendEmail(emailPayload).subscribe(data => {
        this.openNotificationDialog('Thank you', 'We have received your enquiry and will get back to you as soon as we can.');
        dialogRef.close();
      }, (err => {
        this.openNotificationDialog('Opps...', err.message);
        dialogRef.close();
      }));
    } else {
      this.openNotificationDialog('Opps...', 'Some of the fields are invalid. Please try again.');
    }
  }

  openNotificationDialog(title: string, message: string) {
    this.dialog.open(NotificationDialogComponent, {
      maxWidth: undefined,
      maxHeight: undefined,
      data: {
        title: title,
        message: message
      }
    });
  }

  changeBackground(eachCountry, index) {
    if (index !== this.googleMapCounter) {
      this.googleMapCounter = index;
      this.zoom = [eachCountry.googleMapZoom];
      this.center = { lat: eachCountry.googleMapLatitude, lng: eachCountry.googleMapLongitude };
    }
  }

  refreshAOS() {
    AOS.refresh();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
