import { LegalPopupComponent } from './../legal-popup/legal-popup.component';
import { Component, OnInit, Inject } from '@angular/core';
import { FooterService } from './../services/footer.service';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerComponent = null;
  logoImageUrl = '';
  iconImageUrl = '';
  iconImageUrls = [];
  constructor(private footerService: FooterService, private dialog: MatDialog) { }

  private REST_API_ENDPOINT = '';
  // private REST_API_ENDPOINT = environment.apiEndpoint;
  ngOnInit() {
    this.footerService.getFooter().subscribe(data => {
      this.footerComponent = data[0];
      this.logoImageUrl = this.REST_API_ENDPOINT + this.footerComponent.Logo.url;
      for (const eachIconLink of this.footerComponent.IconLink) {
        this.iconImageUrls.push(this.REST_API_ENDPOINT + eachIconLink.Icon.url);
      }
    });
  }

  openDialog(eachPopupInfo) {
    this.dialog.open(LegalPopupComponent, {
      // height: '100%',
      maxWidth: undefined,
      maxHeight: undefined,
      data: { eachPopupInfo: eachPopupInfo }
    });
  }
}
