import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-snippet-popup',
  templateUrl: './video-snippet-popup.component.html',
  styleUrls: ['./video-snippet-popup.component.scss']
})
export class VideoSnippetPopupComponent implements OnInit {
  sanitizedVideoUrl: any = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.eachSnippetPopupInfo.PopupVideoLink);
  }
}
