import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PeopleComponent } from './people/people.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { BrandsComponent } from './brands/brands.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'brands', component: BrandsComponent },
  { path: 'sustainability', component: SustainabilityComponent },
  { path: 'people', component: PeopleComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
