import { NavbarService } from './../services/navbar.service';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navbarComponent = null;
  isToggled: boolean;
  isPristine: boolean;
  currentPath = '';
  constructor(
    private navbarService: NavbarService,
    private location: Location,
    private router: Router,
    private titleService: Title
  ) {
    this.isPristine = true;
  }

  ngOnInit() {


    this.navbarService.getNavbar().subscribe(data => {
      this.navbarComponent = data[0];
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.location.path();
        if (this.navbarComponent){
          const navbarTitle = this.navbarComponent.NavbarItem.find(x => x.URL === this.currentPath);
          if (navbarTitle) {
            this.titleService.setTitle(navbarTitle.Title + ' | Suntory Beverage & Food Asia Pacific');
          } else {
            this.titleService.setTitle('Suntory Beverage & Food Asia Pacific');
          }
        }
      }
    });
  }

  toggle() {
    this.isPristine = false;
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      // make scrollbar hidden
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'initial';
    }
  }

}
