import { Component, OnInit } from '@angular/core';
import { BrandsService } from '../services/brands.service';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrandsPopupComponent } from '../brands-popup/brands-popup.component';
import * as AOS from 'aos';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  brandsComponent = null;
  brandsImageUrl1 = [];
  brandsImageUrl2 = [];
  bannerImageUrl = '';
  private REST_API_ENDPOINT = '';
  private ASSETS_ENDPOINT = environment.assetsEndpoint;
  constructor(
    private brandsService: BrandsService, private dialog: MatDialog) { }

  ngOnInit() {
    this.brandsService.getBrands().subscribe(data => {
      this.brandsComponent = data[0];
      this.bannerImageUrl = this.ASSETS_ENDPOINT + this.brandsComponent.BannerImage.url.replace(/^(.*?)\.com/, '');
      for (const eachBrand of this.brandsComponent.Brand) {
        this.brandsImageUrl1.push(this.ASSETS_ENDPOINT + eachBrand.Logo.url.replace(/^(.*?)\.com/, ''));
      }
      for (const eachBrand of this.brandsComponent.Brand2) {
        this.brandsImageUrl2.push(this.ASSETS_ENDPOINT + eachBrand.Logo.url.replace(/^(.*?)\.com/, ''));
      }
      AOS.refresh();
    });
  }

  openDialog(eachBrandPopupInfo) {
    this.dialog.open(BrandsPopupComponent, {
      panelClass: 'general-dialog',
      maxWidth: undefined,
      maxHeight: undefined,
      data: { eachBrandPopupInfo: eachBrandPopupInfo }
    });
  }

  refreshAOS() {
    AOS.refresh();
  }
}
