<div class="notification-popup-container">
  <div class="title-container">
    <h1 class="big" mat-dialog-title>{{data.title}}</h1>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-content-container" mat-dialog-content>
    <div class="popup-text">
      {{data.message}}
    </div>
  </div>
</div>
