<!-- THIS IS FOR VIEW > PHONE -->
<div *ngIf="footerComponent" class="footer-container">
  <div class="footer-top-container">
    <div class="footer-left-container">
      <img class="footer-logo" [src]="logoImageUrl">
      <div class="iconLinks-container">
        <div *ngFor="let eachIconLink of footerComponent.IconLink; let i = index" class="each-icon">
          <a [href]="eachIconLink.Link" target="_blank">
            <img class="iconLink" [src]="iconImageUrls[i]">
          </a>
        </div>
      </div>
    </div>
    <div class="footer-right-container">
      <span class="text-link-column" *ngFor="let eachTextLink of footerComponent.TextLink">
        <div class="link-header">{{eachTextLink.Title}}</div>
        <span *ngFor="let eachNestedTextLink of eachTextLink.TextLink">
          <div>
            <a class="link-text" [href]="eachNestedTextLink.Url">
              {{eachNestedTextLink.Label}}
            </a>
          </div>
        </span>
        <span *ngFor="let eachNestedPopUp of eachTextLink.PopUp">
          <div>
            <a class="link-text" (click)="openDialog(eachNestedPopUp)">
              <!-- <a class=" link-text" [href]="eachNestedPop.Url"> -->
              {{eachNestedPopUp.Label}}
              <!-- </a> -->
            </a>
          </div>
        </span>
      </span>
    </div>
  </div>
  <div class="copyright-container">
    <p>{{footerComponent.Copyright}}</p>
  </div>
</div>

<!-- THIS IS FOR VIEW <= PHONE -->
<div *ngIf="footerComponent" class="footer-container-phone">
  <div class="footer-top-container-phone">
    <img src="../../assets/img/suntory.svg" class="home-logo" alt="logo" id="logo">
    <div class="iconLinks-container-phone">
      <div *ngFor="let eachIconLink of footerComponent.IconLink; let i = index" class="each-icon">
        <a [href]="eachIconLink.Link" target="_blank">
          <img class="iconLink" [src]="iconImageUrls[i]">
        </a>
      </div>
    </div>
  </div>
  <mat-accordion class="links-accordion">
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let eachTextLink of footerComponent.TextLink">
      <mat-expansion-panel-header style="padding-left:0;padding-right: 1rem;">
        <span>
          {{eachTextLink.Title}}
        </span>
      </mat-expansion-panel-header>
      <div *ngFor="let eachNestedTextLink of eachTextLink.TextLink" style="margin-bottom: 0.3rem;">
          <a class="link-text" [href]="eachNestedTextLink.Url">
            {{eachNestedTextLink.Label}}
          </a>
      </div>
      <div *ngFor="let eachNestedPopUp of eachTextLink.PopUp" style="margin-bottom: 0.3rem;">
          <a class="link-text" (click)="openDialog(eachNestedPopUp)">
            {{eachNestedPopUp.Label}}
          </a>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="copyright-container-phone">
    <p>{{footerComponent.Copyright}}</p>
  </div>
</div>
