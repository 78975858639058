<div class="snippet-popup-container">
  <div class="title-container">
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-writeup-container" mat-dialog-content>
    <img [src]="popUpImageUrl">
    <div markdown  class="popup-text">
      {{data.eachSnippetPopupInfo.PopUpDescription}}
    </div>
  </div>
</div>
